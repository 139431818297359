<template>
    <div>
        <div class="w-100 p-2 d-flex justify-content-end">
            <language-dropdown />
        </div>
        <div class="d-flex justify-content-center align-items-center px-4 h-100">
            <div>
                <div class="d-flex justify-content-center">
                    <b-img
                        :src="appLogoImageName"
                        alt="logo"
                        style="width: 250px;"
                    />
                </div>
                <div class="mt-4 card text-center" style="font-size: 17px;">

                    <div v-if="step === 0">
                        <div style="font-weight: bold; font-size: 20px;">
                            {{ $t('password.forgotten_password') }}
                        </div>


                        <div class="mt-1 mb-2">
                            {{ $t('password.enter_email_to_continue') }}
                        </div>

                        <validation-observer ref="forgottenPassword">

                            <div class="text-left">
                                <b-form-group :label="$t('form_data.email')">
                                    <validation-provider
                                        #default="{ errors }"
                                        :name="$t('form_data.email')"
                                        rules="required|email"
                                    >
                                        <b-form-input v-model="data.email" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>

                        </validation-observer>

                        <b-button class="mt-2" variant="primary" style="font-size: 17px;" @click="sendPasswordResetLink">
                            {{ $t('password.reset_password') }}
                        </b-button>

                    </div>
                    <div v-else-if="step === 1">
                        <div class="mb-2" style="font-size: 17px;">
                            {{ $t('confirm.fetching') }}
                        </div>
                        <div class="d-flex justify-content-center">
                            <div class="loader" />
                        </div>
                    </div>
                    <div v-else-if="step === 2">
                        <div class="mb-2" style="font-size: 17px;">
                            {{ $t('password.email_reset_sent') }}.
                        </div>

                        <b-button class="mt-2" variant="primary" style="font-size: 17px;" @click="$router.push({name:'login'})">
                            {{ $t('password.back_to_login') }}
                        </b-button>

                    </div>
                    <div v-else-if="step === 3">
                        <div class="mb-2" style="font-size: 17px;">
                            {{$t('password.user_with_email')}} <b>{{data.email}}</b> {{$t('password.does_not_exist')}}.
                        </div>

                        <div class="d-flex justify-content-center">
                            <b-button class="mt-2 mr-2" variant="primary" style="font-size: 17px;" @click="step = 0">
                                {{ $t('general.back') }}
                            </b-button>
                            <b-button class="mt-2" variant="primary" style="font-size: 17px;" @click="$router.push({name:'login'})">
                                {{$t('login_page')}}
                            </b-button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { $themeConfig } from '@themeConfig'
    import {BFormInput, BFormGroup, BButton, BImg} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import LanguageDropdown from '@/components/LanguageDropdown.vue'

    export default {
        components:{
            LanguageDropdown,
            ValidationObserver,
            ValidationProvider,
            BImg,
            BFormInput,
            BFormGroup,
            BButton
        },
        computed: {
            isUserLoggedIn() {
                return this.$store.getters['user/loggedIn']
            }
        },
        setup() {
            // App Name
            const { appLogoImageName } = $themeConfig.app
            return {
                appLogoImageName
            }
        },
        data() {
            return {
                step: 0,
                data: {
                    email: ''
                }
            }
        },
        methods: {
            async sendPasswordResetLink() {
                const valid = await this.$refs.forgottenPassword.validate()
                if (!valid) {
                    return
                }

                this.step = 1

                try {

                    await this.$http.post('/api/client/v1/forgotten_password/send', this.data)

                    this.step = 2
                } catch (err) {
                    Sentry.captureException(err)
                    this.step = 3
                }

            }
        },
        async mounted() {
            if (this.isUserLoggedIn) {
                await this.$router.replace({name: 'home'})
            }

        }
    }
</script>

<style>
.card{
  border-radius: 15px;
  padding: 25px 30px;
}

.loader{
  animation: l43 1s infinite linear;
  height: 30px;
  aspect-ratio: 2.5;
}

.dark-layout .loader{
  --_g: no-repeat radial-gradient(farthest-side, #aaaaaa 90%, rgba(194, 17, 17, 0));
  background:var(--_g), var(--_g), var(--_g), var(--_g);
  background-size: 20% 50%;
}
.light-layout .loader{
  --_g: no-repeat radial-gradient(farthest-side, #606060 90%, rgba(194, 17, 17, 0));
  background:var(--_g), var(--_g), var(--_g), var(--_g);
  background-size: 20% 50%;
}

@keyframes l43 {
  0%     {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
  16.67% {background-position: calc(0*100%/3) 0   ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
  33.33% {background-position: calc(0*100%/3) 100%,calc(1*100%/3) 0   ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
  50%    {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 100%,calc(2*100%/3) 0   ,calc(3*100%/3) 50% }
  66.67% {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 100%,calc(3*100%/3) 0   }
  83.33% {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 100%}
  100%   {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
}

</style>